import Link from "next/link";
import { Routes } from "@blitzjs/next";
import { Typography } from "@mui/material"
import FoSLogo from "./FoSLogo"
import { LoginButton } from "./LoginButton"
import { NavBar, NavLogo, ButtonWrapper } from "./Nav.styles"
import { ContactButton } from "./ContactButton"
// import { StudioButton } from "./StudioButton"

export const Nav = () => {
  return (
    <nav>
      <NavBar>
        <NavLogo>
          <Link href={Routes.Home()} passHref legacyBehavior>
            <a>
              <FoSLogo height="100%" invert />
            </a>
          </Link>
          <Typography fontFamily="abel" lineHeight="1.5rem" ml={1} textTransform="uppercase">
            Foto&apos;s
            <br />- Op -<br />
            School
          </Typography>
        </NavLogo>

        <ButtonWrapper>
          {/*<StudioButton />*/}
          <ContactButton />
          <LoginButton />
        </ButtonWrapper>
      </NavBar>
    </nav>
  )
}
