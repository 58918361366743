import { FC } from "react"
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton"
import { useFormContext } from "react-hook-form"

export const SubmitButton: FC<LoadingButtonProps> = (props) => {
  const {
    formState: { isSubmitting },
  } = useFormContext()

  return <LoadingButton type="submit" loading={isSubmitting} {...props} />
}
