import Link from "next/link";
import { Routes } from "@blitzjs/next";
import { Button } from "@mui/material"
import { useTranslation } from "next-i18next"

export const ContactButton = () => {
  const { t } = useTranslation()

  return (
    <Link href={Routes.Contact()} passHref legacyBehavior>
      <Button color="secondary" size="large" variant="text">
        {t("navContact")}
      </Button>
    </Link>
  )
}
