import { useQuery, invalidateQuery } from "@blitzjs/rpc"
import getSchoolConfig from "../queries/getSchoolConfig"
import * as z from "zod"

export const refetchConfig = () => invalidateQuery(getSchoolConfig)

export enum CONFIG_KEY {
  useLoginCodes = "useLoginCodes",
  useOnlyLoginCodes = "useOnlyLoginCodes",
  useSmartschool = "useSmartschool",
  welcomeMessage = "welcomeMessage",
  useFreeDownloads = "useFreeDownloads",
}

const booleanConfigs = [
  CONFIG_KEY.useLoginCodes,
  CONFIG_KEY.useOnlyLoginCodes,
  CONFIG_KEY.useSmartschool,
  CONFIG_KEY.useFreeDownloads,
]

const Config = z.object({
  [CONFIG_KEY.useLoginCodes]: z.boolean().optional(),
  [CONFIG_KEY.useOnlyLoginCodes]: z.boolean().optional(),
  [CONFIG_KEY.useSmartschool]: z.boolean().optional(),
  [CONFIG_KEY.useFreeDownloads]: z.boolean().optional(),
  [CONFIG_KEY.welcomeMessage]: z.string().optional(),
})

export const useSchoolConfig = (schoolSlug?: string) => {
  const [data] = useQuery(getSchoolConfig, {
    schoolSlug,
  })

  return data && data.length
    ? Config.parse(
        data.reduce(
          (agg, item) => ({
            ...agg,
            [item.key]: booleanConfigs.includes(item.key as CONFIG_KEY)
              ? Boolean(parseInt(item.value))
              : item.value,
          }),
          {}
        )
      )
    : null
}
