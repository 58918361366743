import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { TextFieldProps } from "@mui/material"
import { TextField } from "./TextField"

type Props = TextFieldProps & {
  name: string
  transform?: {
    input?: (value: string) => string
    output?: (
      value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  }
}

export const FormTextField = ({ name, transform, ...textFieldProps }: Props) => {
  const {
    control,
    formState: { isSubmitting, errors },
  } = useFormContext()
  const error = Array.isArray(errors[name])
    ? errors[name].join(", ")
    : errors[name]?.message || errors[name]

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <TextField
          name={name}
          disabled={isSubmitting}
          variant="outlined"
          // size="small"
          fullWidth
          autoComplete="off"
          error={Boolean(error)}
          helperText={error}
          value={value}
          onChange={
            textFieldProps.type === "number"
              ? (event) =>
                  onChange({
                    ...event,
                    target: { ...event.target, value: parseFloat(event.target.value) },
                  })
              : transform?.output
              ? (event) => onChange(transform?.output?.(event))
              : onChange
          }
          {...textFieldProps}
        />
      )}
    />
  )
}
