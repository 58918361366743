import {
  alpha,
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
  styled,
  TextFieldProps,
} from "@mui/material"
import { FC } from "react"

// Based on the bootstrap version:
// https://mui.com/components/text-fields/#customization
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2.5),
  },
  display: "flex",
  border: "1px solid #d6d9de",
  borderRadius: theme.shape.borderRadius,
  padding: `0 ${theme.spacing(1)}`,
  minHeight: 40,

  "&:hover, &.Mui-focused": {
    borderColor: "#adb3bd",
  },
}))

export const TextField: FC<TextFieldProps> = ({
  name,
  inputRef,
  label,
  id: idOverride,
  value,
  onChange,
  defaultValue,
  placeholder,
  InputProps,
  fullWidth,
  multiline,
  required,
  minRows,
  error,
  helperText,
  type,
}) => {
  const id = idOverride || `bootstrap-input-${name}`

  return (
    <FormControl variant="standard" fullWidth={fullWidth} required={required} error={error}>
      <InputLabel shrink htmlFor={id} required={required} error={error}>
        {label}
      </InputLabel>
      <BootstrapInput
        error={error}
        defaultValue={defaultValue}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth={fullWidth}
        multiline={multiline}
        required={required}
        minRows={minRows}
        inputRef={inputRef}
        type={type}
        name={name}
        {...InputProps}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
