import { createTheme } from "@mui/material/styles"
import { alpha, lighten } from "@mui/material"
import * as React from "react"
import { FontStyleOptions } from "@mui/material/styles/createTypography"

// Avoid using colors directly, always go via @material-ui/core/theme
// In rare cases this doesn't work. (see PageLoader)
// export const colors = {
//   primary: {
//     light: lighten('#EFAC00', 0.7),
//     main: '#EFAC00',
//     contrastText: 'white',
//   },
//   secondary: {
//     main: '#F00C59',
//   },
//   // primary: {
//   //   // light: lighten('#FF9064', 0.7),
//   //   light: '#FF9064',
//   //   main: '#FF7B4A',
//   //   contrastText: 'white',
//   // },
//   // secondary: {
//   //   // main: '#00C8FF',
//   //   // dark: '#00ADEA',
//   //   // main: '#FF9064',
//   //   main: '#8D76FF',
//   //   dark: '#664DE3',
//   // },
//   red: {
//     main: '#FF002C',
//   },
//   white: '#FFFFFF',
//   grey: {
//     50: '#F1F0F0',
//     100: '#F6F6F6',
//     200: '#D8D8D8',
//     600: '#666666',
//     900: '#333333',
//   },
// };

export const colors = {
  primary: {
    light: "#FDCF84",
    main: "#FF9637",
    contrastText: "#fff",
  },
  secondary: {
    main: "#B52C51",
  },
  // primary: {
  //   // light: lighten('#FF9064', 0.7),
  //   light: '#FF9064',
  //   main: '#FF7B4A',
  //   contrastText: 'white',
  // },
  // secondary: {
  //   // main: '#00C8FF',
  //   // dark: '#00ADEA',
  //   // main: '#FF9064',
  //   main: '#8D76FF',
  //   dark: '#664DE3',
  // },
  red: {
    main: "#FF002C",
  },
  white: "#FFFFFF",
  grey: {
    50: "#F1F0F0",
    100: "#F6F6F6",
    200: "#D8D8D8",
    600: "#666666",
    900: "#3A3A55",
  },
}

declare module "@mui/material/styles" {
  interface PaletteOptions {
    colors: typeof colors
  }

  interface Palette {
    colors: typeof colors
  }

  export interface TypeBackground {
    alt: string
  }
}

declare module "@mui/material/styles/createTypography" {
  interface TypographyOptions {
    fontFamilyAlt: React.CSSProperties["fontFamily"]
  }

  interface Typography {
    fontFamilyAlt: React.CSSProperties["fontFamily"]
  }
}

const theme = createTheme({
  palette: {
    colors,
    grey: colors.grey,
    primary: colors.primary,
    secondary: colors.secondary,
    background: { default: colors.white, alt: colors.grey[100] },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: "blur(2px)",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        color: "primary",
        variant: "contained",
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
          // padding: "8px 16px",
        },
        containedPrimary: {
          boxShadow: `0 10px 25px ${alpha(colors.primary.main, 0.5)}`,
        },
        containedSecondary: {
          boxShadow: `0 10px 25px ${alpha(colors.secondary.main, 0.5)}`,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 1,
      },
    },
  },
  /*  overrides: {
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 18px) scale(1)",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "14.5px 14px",
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 700,
      },
    },
    MuiCollapse: {
      // @TODO Blitz 0.39 upgrade
      // container: {
      //   backgroundColor: colors.grey[50],
      // },
    },
    MuiButton: {
      root: {
        textTransform: "capitalize",
        fontWeight: 700,
        padding: "8px 16px",
      },
      contained: {
        backgroundColor: colors.grey[900],
        color: colors.white,
        "&:hover": {
          backgroundColor: darken(colors.grey[900], 0.7),
        },
      },
      containedSizeLarge: {
        padding: "12px 16px",
      },
      containedSizeSmall: {
        padding: "5px 15px",
        fontSize: "1.4rem",
      },
      outlinedSizeSmall: {
        minWidth: "auto",
      },
    },
    // MuiTab
  },*/
  typography: {
    htmlFontSize: 10,
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    fontFamilyAlt: ["Abel", "sans-serif"].join(","),
    h1: {
      fontSize: "4.4rem",
      letterSpacing: "0.1rem",
      fontWeight: 700,
      fontFamily: ["Abel", "sans-serif"].join(","),
      "@media screen and (max-width: 960px)": {
        fontSize: "4rem",
      },
      "@media screen and (max-width: 800px)": {
        fontSize: "3rem",
      },
      "@media screen and (max-width: 648px)": {
        fontSize: "4.4rem",
      },
      "@media screen and (max-width: 540px)": {
        fontSize: "3rem",
      },
      "@media screen and (max-width: 400px)": {
        fontSize: "2.4rem",
      },
    },
    h2: {
      fontSize: "2.0rem",
      letterSpacing: "0.1rem",
      fontFamily: ["Abel", "sans-serif"].join(","),
      lineHeight: 1.4,
      "@media screen and (max-width: 648px)": {
        fontSize: "2.4rem",
      },
      "@media screen and (max-width: 540px)": {
        fontSize: "1.8rem",
      },
    },
    h3: {
      fontSize: "1.8rem",
      letterSpacing: "0.1rem",
      fontFamily: ["Abel", "sans-serif"].join(","),
    },
  },
})
// console.log({ theme });
export default theme
