import { LinearProgress, styled } from "@mui/material"
import React from "react"
import { colors } from "src/app/styles/theme"

const Wrapper = styled("div")`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  .MuiLinearProgress-colorPrimary {
    background-color: ${colors.primary.light};
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: ${colors.primary.main};
  }
`

const PageLoader = (props) => {
  return (
    <Wrapper>
      <LinearProgress color="primary" />
    </Wrapper>
  )
}

export default PageLoader
