import Link from "next/link";
import { Routes } from "@blitzjs/next";
import { Box, Link as MuiLink, Typography, Grid, styled } from "@mui/material"
import FoSLogo from "./FoSLogo"
import { PageContainer } from "./PageContainer"
import { useTranslation } from "next-i18next"

const FooterWrapper = styled("footer")(({ theme }) => ({
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  background: theme.palette.grey[100],
}))
const UL = styled("ul")({ listStyleType: "none", "& > li": { marginBottom: "1rem" } })

export const Footer = () => {
  const { t } = useTranslation("common")

  return (
    <FooterWrapper>
      <PageContainer>
        <Grid container spacing={4}>
          <Grid item>
            <Box display="flex" mb={2} sx={{ opacity: 0.75 }}>
              <FoSLogo height={48} />
              <Typography
                textAlign="center"
                display="inline"
                fontFamily="Abel"
                lineHeight={1}
                ml={1}
                textTransform="uppercase"
              >
                Foto&apos;s
                <br />
                - op -<br />
                School
              </Typography>
            </Box>
            <Typography variant="body2" gutterBottom fontSize={"1rem"}>
              {t("footerAllRights")}
            </Typography>
          </Grid>
          <Grid item>
            <UL>
              <li>
                <Link href={Routes.Home()} passHref legacyBehavior>
                  <MuiLink variant="body2" display="block">
                    {t("navHome")}
                  </MuiLink>
                </Link>
              </li>
              <li>
                <Link href={Routes.DashboardLogin()} passHref legacyBehavior>
                  <MuiLink variant="body2" display="block">
                    {t("navDashboard")}
                  </MuiLink>
                </Link>
              </li>
              <li>
                <Link href={Routes.StudioLogin()} passHref legacyBehavior>
                  <MuiLink variant="body2" display="block">
                    {t("navStudio")}
                  </MuiLink>
                </Link>
              </li>
              <li>
                <Link href={Routes.Contact()} passHref legacyBehavior>
                  <MuiLink variant="body2" display="block">
                    {t("navContact")}
                  </MuiLink>
                </Link>
              </li>
              <li>
                <Link href={Routes.PrivacyPage()} passHref legacyBehavior>
                  <MuiLink variant="body2" display="block">
                    {t("navPrivacy")}
                  </MuiLink>
                </Link>
              </li>
            </UL>
          </Grid>
        </Grid>
      </PageContainer>
    </FooterWrapper>
  )
}
