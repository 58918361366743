import { styled } from "@mui/material"

export const NavBar = styled("div")({
  // height: 88,
  paddingTop: 32,
  paddingBottom: 28,
  display: "flex",
  textAlign: "center",
  justifyContent: "space-between",
})
export const NavLogo = styled("div")({
  display: "flex",
  textAlign: "center",
  // alignItems: "center",
})
export const ButtonWrapper = styled("div")(({ theme }) => ({
  "& > a": {
    marginLeft: theme.spacing(2),
  },
}))
