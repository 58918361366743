import { DetailedHTMLProps, FC, ImgHTMLAttributes } from "react"
import { styled } from "@mui/material"

type LogoProps = {
  height?: string | number
  width?: string | number
  alt: string
} & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
// eslint-disable-next-line @next/next/no-img-element
const Logo = styled(({ height, width, alt, ...other }: LogoProps) => <img alt={alt} {...other} />)(
  ({ height, width }) => ({
    opacity: 0.87,
    height: typeof height === "number" ? `${height}px` : height,
    width: typeof width === "number" ? `${width}px` : width,
  })
)

type Props = { height?: string | number; invert?: boolean; width?: string | number }

const FoSLogo: FC<Props> = ({ height = "100%", invert, width = "auto" }) => {
  return (
    <Logo
      src={invert ? "/images/fos/fosLogoInverted.svg" : "/images/fos/fosLogo.png"}
      alt="Foto's Op School logo"
      height={height}
      width={width}
    />
  )
}

export default FoSLogo
