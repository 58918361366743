import { useMutation } from "@blitzjs/rpc"
import { AuthenticationError, PromiseReturnType } from "blitz"
import { Box, InputAdornment, Paper, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useTranslation } from "next-i18next"
import EmailOutline from "mdi-material-ui/EmailOutline"
import LockOutline from "mdi-material-ui/LockOutline"
import { Login } from "src/app/auth/validations"
import { Form, FORM_ERROR } from "src/app/core/components/Form"
import { FormTextField } from "src/app/core/components/formFields/FormTextField"
import { SubmitButton } from "src/app/core/components/formFields/SubmitButton"
import { PageContainer } from "src/app/core/components/PageContainer"
import { Nav } from "src/app/core/components/Nav"
import { Footer } from "src/app/core/components/Footer"
import login from "../mutations/login"
import { useSearchParams } from "next/navigation"
import { z } from "zod"

type LoginFormProps = {
  onSuccess?: (user: PromiseReturnType<typeof login>) => void
  loginType?: "emailCode" | "default" | "code"
  initialValues?: Partial<z.infer<typeof Login>>
}

export const MyLoginForm = ({
  onSuccess,
  loginType = "default",
  initialValues,
}: LoginFormProps) => {
  const { t } = useTranslation("common")
  const [loginMutation] = useMutation(login)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const searchParams = useSearchParams()
  const loginCode = searchParams.get("code")

  const useCode = ["emailCode", "code"].includes(loginType)

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <div>
        <PageContainer>
          <Nav />
        </PageContainer>
      </div>
      <Box maxWidth={500} margin="0 auto" flex={"1 0 auto"}>
        <Paper
          sx={{
            p: isSmallScreen ? 4 : 8,
            pb: isSmallScreen ? 2 : 4,
            mb: 8,
            boxShadow: "0 24px 64px rgb(38 33 74 / 10%)",
          }}
          elevation={0}
        >
          <Form
            schema={Login}
            initialValues={{ email: "", ...initialValues, password: loginCode ?? "" }}
            onSubmit={async (values) => {
              try {
                const user = await loginMutation({ ...values, useCode })
                onSuccess?.(user)
              } catch (error: any) {
                if (error instanceof AuthenticationError) {
                  return { [FORM_ERROR]: "Sorry, those credentials are invalid" }
                } else {
                  return {
                    [FORM_ERROR]:
                      "Sorry, we had an unexpected error. Please try again. - " + error.toString(),
                  }
                }
              }
            }}
          >
            <Typography variant="h1" sx={{ mb: "2rem" }} textAlign="center">
              {t("authTitle")}
            </Typography>

            {useCode ? (
              <>
                {loginType === "emailCode" && (
                  <FormTextField
                    name="email"
                    label={t("fieldEmailForCode")}
                    placeholder={t("fieldEmailForCodePlaceholder")}
                    helperText={t("fieldEmailForCodeHelper", {
                      defaultValue:
                        "Dit e-mail adres zal gebruikt worden om de bestelbevestiging te sturen.",
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailOutline sx={{ opacity: 0.5 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                <FormTextField
                  name="password"
                  label={t("fieldLoginCode")}
                  placeholder={t("fieldLoginCodePlaceholder")}
                  helperText={t("fieldLoginCodeHelper", {
                    defaultValue: `Geef 1 of meerder unieke login codes in door comma gescheiden. Bv: "ABC1234, XYZ9876"`,
                  })}
                  transform={{
                    output: (e) => ({
                      ...e,
                      target: { ...e.target, value: e.target.value.toUpperCase() },
                    }),
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutline sx={{ opacity: 0.5 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            ) : (
              <>
                <FormTextField
                  name="email"
                  label={t("fieldEmail")}
                  placeholder={t("fieldEmailPlaceholder")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutline sx={{ opacity: 0.5 }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormTextField
                  name="password"
                  label={t("fieldPassword")}
                  placeholder={t("fieldPasswordPlaceholder")}
                  type="password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutline sx={{ opacity: 0.5 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
            <SubmitButton sx={{ mt: 4, mb: 4 }} fullWidth size="large">
              {t("btnLogin")}
            </SubmitButton>
          </Form>
        </Paper>
      </Box>
      <Footer />
    </Box>
  )
}

export default MyLoginForm
