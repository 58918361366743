import { useSession } from "@blitzjs/auth"
import { useQueryErrorResetBoundary } from "@blitzjs/rpc"
import { AppProps, ErrorBoundary, ErrorComponent, ErrorFallbackProps } from "@blitzjs/next"

import { appWithTranslation } from "next-i18next"
import { GlobalStyles } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import React, { Suspense, useEffect } from "react"
import theme from "src/app/styles/theme"
import createEmotionCache from "src/app/styles/createEmotionCache"
import { CacheProvider, EmotionCache } from "@emotion/react"
import { ConfirmProvider } from "material-ui-confirm"
import PageLoader from "../app/core/components/PageLoader"

import Sentry from "integrations/sentry"
// import LogRocket from "integrations/logrocket"
import { PreconditionFailedError } from "../app/auth/utils"
import { useCurrentUser } from "../app/core/hooks/useCurrentUser"
import { AuthenticationError, AuthorizationError } from "blitz"
import { withBlitz } from "src/blitz-client"
import MyLoginForm from "../app/auth/components/MyLoginForm"
import { useRouter } from "next/router"
import { useSchoolConfig } from "../app/shop/shared/hooks/useSchoolConfig"

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()
const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      html: { height: "100%", fontSize: "62.5%" },
      body: {
        whiteSpace: "pre-line",
        height: "100%",
        fontSize: "1.6rem",
        overflowX: "hidden",
        "&>div:first-child": { height: "100%" },
      },
      a: {
        textDecoration: "none",
        color: "inherit",
      },
    }}
  />
)

export default appWithTranslation(
  withBlitz(function App({
    Component,
    pageProps,
    emotionCache = clientSideEmotionCache,
  }: AppProps & {
    emotionCache?: EmotionCache
  }) {
    const session = useSession({ suspense: false })
    const getLayout = Component.getLayout || ((page) => page)

    useEffect(
      function identifyRemoteLogging() {
        if (session.userId) {
          // @ts-ignore
          Sentry.setUser({ id: session.userId.toString() })
          // @ts-ignore
          // LogRocket.identify(session.userId.toString())
        }
      },
      [session]
    )

    return (
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          {inputGlobalStyles}
          <CssBaseline />
          <Suspense fallback={<PageLoader />}>
            <ErrorBoundary
              onError={(error, componentStack) => {
                // LogRocket.getSessionURL((sessionURL) => {
                Sentry.captureException(error, {
                  contexts: { react: { componentStack } },
                  // extra: { sessionURL },
                })
                // })
              }}
              FallbackComponent={RootErrorFallback}
              onReset={useQueryErrorResetBoundary().reset}
            >
              <ConfirmProvider>
                <Suspense fallback={<PageLoader />}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    {getLayout(<Component {...pageProps} />)}
                  </LocalizationProvider>
                </Suspense>
              </ConfirmProvider>
            </ErrorBoundary>
          </Suspense>
        </ThemeProvider>
      </CacheProvider>
    )
  })
)

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  const user = useCurrentUser()
  const { route, query, push } = useRouter()
  const schoolSlug = query?.school as string
  const schoolConfig = useSchoolConfig(schoolSlug)

  const redirectToSmartschool =
    schoolConfig?.useSmartschool &&
    error instanceof AuthenticationError &&
    route !== "/[school]/login"

  useEffect(() => {
    if (redirectToSmartschool) {
      void push(`/${schoolSlug}/login`)
    }
  }, [push, redirectToSmartschool, schoolSlug])

  if (redirectToSmartschool) return null

  if (error instanceof AuthenticationError) {
    return (
      <MyLoginForm
        onSuccess={resetErrorBoundary}
        loginType={
          !query?.school ? "default" : schoolConfig?.useOnlyLoginCodes ? "code" : "emailCode"
        }
        initialValues={{
          email: schoolConfig?.useOnlyLoginCodes ? `student@${schoolSlug}.be` : "",
        }}
      />
    )
  } else if (error.name === "PreconditionFailedError") {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title={
          `Je bent momenteel aangemeldt met "${
            user?.email || user?.name
          }" en kan deze pagina niet bekijken. Gelieve eerst af te melden` as string
        }
      />
    )
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    return (
      <ErrorComponent statusCode={error.statusCode || 400} title={error.message || error.name} />
    )
  }
}
